.bandeau {
  display: flex;
  position: relative;
  gap: 40px;
  align-items: center;
  justify-content: center;
  top: 0;
  z-index: 101;
  background: #8f7d76;
  padding: 30px;
  height: 220px;
  width: 100%;
  color: white;
  font-size: 1.8rem;
  font-weight: 300;
}

.App{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bandeau img {
  margin-left: -140px;
}

.bandeau p {
  font-size: 1.16rem;
  color: #392f2a;
}

.bandeau h1 {
  font-size: 2.5rem;
  color: #e1dfdd;
  text-align: unset;
  font-weight: 300;
  text-shadow: #392f2ad5 0px 2px 12px;
}
.mandala {
  position: fixed;
  bottom: -100px;
  right: -50px;
  height: 1000px;
  opacity: 0.3;
}

.footer-container {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 380px;
  background: #8f7d7697;
  padding: 70px 60px;
  P {
    font-size: 18px;
  }
}

.footer {
  display: flex;
  justify-content: space-between;
  gap: 60px;
  color: white;
  font-size: 1.2rem;
  font-weight: 300;
  h1 {
    font-size: 29px;
    color: #e1dfdd;
    text-align: unset;
    font-weight: 300;
    text-shadow: rgb(57 47 42 / 84%) 0px 2px 12px;
  }
}

.footer-right {
  padding: 16px;
  background: #f5f5f51a;
  border-radius: 10px;
  margin-bottom: 12px;
  height: fit-content;
}

.hiddenmodal {
  position: fixed;
  top: 0;
  right: 0;
  width: 0px;
  height: 0px;
  background: #000000a6;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 720px;
  background: #d8ccc7;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  opacity: 1;
  transition: opacity 0.65s ease-in-out;
}

.modal-bg {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: #000000a6;
  z-index: 102;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
}

.hidden {
  opacity: 0;
  top: -100%;
  transition: opacity 0.66s ease-in-out;
}
