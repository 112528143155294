
.one-page{
 display: flex;
 flex-direction: column;
 align-items: center;
 justify-content: center;
 height: 100%;
}
.one-page::-webkit-scrollbar{
  display: none;
}
  
.container{
 position: relative;
 width: 100%;
 max-width: 1200px;
 margin-bottom: 100px;
}

.container section{
  padding-top: 100px;
  position: relative;
  padding-bottom: 50px;
  width: 100%;
  h2{
    margin-bottom: 16px;
  }
}


.white-bg{
  position: absolute;
  left: 50%;
  height: 90vh;
  transform: translate(-50%);
  top: -90px;
  z-index: -1;
}

.white-bg-2{
  position: absolute;
  left: 50%;
  height: 100vh;
  transform: translate(-50%);
  top: -60px;
  z-index: -1;
}
.mint-s{
  display: flex;
  justify-content: center;
  height: 600px;
}

.mint-c{
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  // filter: blur(4px);
  button{
    width: 200px;
  }
  p{
    color: rgb(255, 145, 240);
    font-size: 14px;
  }

  .mint-menu{
    display: flex;
    gap: 50px;
    font-size: 26px;
    cursor: pointer;
  }
  h3{
    color: rgb(255, 145, 240);
    font-weight: normal;
  }
  h3:hover{
    text-decoration: underline;
  }
  h3.selected{
    text-decoration: underline;
  }

}
.hero-s{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  gap: 12px;
  width: 100%;
  p{
    font-size: 30px;
    text-align: center;
  }
  margin-top: 0 !important;
  padding-top: 100px !important;
}

.hero-btn{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 30px;
  margin-top: 1vh;

  button{
    font-size: 30px;
    height: 45px;
    width: 190px;
  }
}

.nft-slide{
  position: absolute;
  bottom: 0;
  height: 30vh;
  animation: moveSlideshow 100s linear infinite;
}

@keyframes moveSlideshow {
  100% {
    transform: translateX(-40%);
  }
}

.about{
  height: 100% !important;
  p{
    font-size: 20px;
  }
  a:hover{
    text-decoration: underline;
    color: #6FB1FF;
  }
}

.quantity{
  height: 40px;
  width: 140px;
  background-color: #FDE3EE;
  border-radius: 10px;
}
.collection{
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}
.photo{
  height: 370px;
  border-radius: 30px;
  margin-right:20px;
}
.img-bg{
  position: absolute;
  z-index: -1;
  bottom: 2%;
  left: 10%;
  opacity: 0.3;
}
.img-bg-2{
  position: absolute;
  z-index: -1;
  bottom: 50%;
  opacity: 0.3;
}

.question{
  display: flex;
  flex-direction: column;
  background: #8f7d76;
  color: white;
  padding: 14px;
  border-radius: 10px;
  gap: 20px;
  width: 100%;
}

.reponse{
  padding: 24px;
  background: #ffffff0d;
  border-radius: 10px;
  backdrop-filter: blur(3px);
}

.team{
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
padding-bottom: 200px;
 img{
   height: 40vh;
 }


}


.team >div{
  gap: 60px;
  width: 100%;
}
.member{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h2{
    margin-bottom: 0;
    margin-top: 18px;
    font-size: 5vh;
  }
  p{
    padding: 0;
  }

  img{
    border-radius: 10px;
  }
}



@media (max-width: 650px) {

  .collection{
    flex-direction: column;
    align-items: center;
  }

.showcase{
  height: unset;
  width: 90%;
}  
.nft-slide{
  display: none;
}

.mint-s{
  transform: scale(0.7);
}

.one-page{
  overflow-x: hidden;
}

.team >div{
  flex-direction: column;
  gap: 50px;

 h2{
  text-align: center;
 }
 p{
   text-align: center;
 }
 .hero-btn{
   flex-direction: column;
 }

}
}