.navbar {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  top: 0;
  margin-top: -5px;
  width: 100%;
  font-size: 0.9em;
  background: #8f7d76;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
}

.navbar-c{
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 88%;
  height: 78px;
  // border-bottom: 1px solid #f9f9f985;
}
.nav-item{
  display: flex;
}
.nav-item a{
  display: flex;
  align-items: center;
  font-size: 1.1rem;
  font-family: 'Lato', sans-serif;
  color: #e4e4e4;
  height: 26px;
  transition: 0.2s ease-in-out;
  font-weight: 500;
  padding-right: 18px;
  padding-left: 18px;
  border-right:  1px solid #2725233f; ;
}

.nav-item a:hover{
  color: #ddd4cd;
}
.logo {
  display: flex;
  gap:12px;
  font-size: 1.2rem;
  align-items: center;
}
.logo h2{
  font-size: 1.8rem;
}
.media img{
  height: 25px;
}

.main-button.wallet{
  background-color: #be9a7d !important;
}

.connect{
  position: absolute;
  right: 0;
}

@media (max-width: 650px) {

  .nav-item a{
  display: none;
}
.media{
  top:-20px;
}
.connect{
  top: -20px;
  right: 8px;
}

}