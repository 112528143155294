@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@300&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;1,100;1,300;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cinzel&display=swap');
// @font-face {
//   font-family: 'Chubby Crayon';
//   src: url(../font/chubby-crayon.regular.ttf) format('truetype');
// }


* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    text-decoration: none;
    font-style: unset;
    outline: none;
    scroll-behavior: smooth;
  }
  html {
    scroll-behavior: smooth;
  }
body{ 
    // background: linear-gradient(105.99deg, #B0A59E 0%, #796861 100%);
    background: linear-gradient(130deg, #efe5e2 0%, #ab988f 100%);
    background-attachment: fixed;
    font-family: 'Lato', sans-serif;
    font-size: 1.1em;
    height: 100vh;
    width: 100vw;
    overflow-x: hidden; 
    overflow-y: scroll;
    scroll-behavior: smooth;
    color: #000;
}

h1{
  font-size: 12vh;
  color: #000;
  text-align: center;
}

h2{
 margin-bottom: 6px;
 color: #2e2924;
 font-size: 42px;
}
h3{
  margin-bottom: 6px;
  color: #5a4943;
  font-size: 21px;
 }
 
.gap{
  gap: 6px;
}
.gap-{
  gap: 12px;
}

p{
  color: #1a1510c6;
  font-weight: 300;
  i {
    color: #1a1510c6;
    font-weight: 600;

  }
}


.desktop-only {
  @media (max-width: 1000px) {
    display: none !important;
  }
}

.texte-fond{
  padding: 24px;
  background: #ebe0dd80;
  border-radius: 10px;
  backdrop-filter: blur(3px);
}

.flex{
  display: flex;
  align-items: center;
  justify-content: center;
}

.colmumn{
  display: flex;
  flex-direction: column;
}
.row{
  display: flex;
  flex-direction: row;
  gap: 6px;
}
.wrap{
  flex-wrap: wrap;
  cursor: default;
  gap: 6px;
}
.row-center{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
}
//-------Scroll Bar----------
::-webkit-scrollbar-track {
    background:#A39B98;
  }
  
  ::-webkit-scrollbar {
    width: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background: #312924;
    border-radius: 50px;
  }
//----------------------------

//-------Main Button----------

button:hover {
  cursor: pointer;
}

.main-button{
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight:300;
  font-family: 'Lato', sans-serif;
 color: rgb(226, 225, 225);
 border: none;
 height: 40px;
 padding: 0 30px 0 30px;
 font-size: 1.1rem!important;
 transition: 0.33s ease-in-out;
 border-radius: 50px;
 background-color: #77655d !important;
 box-shadow: 0px 0px 14px 4px #be9a7d71;
}

.main-button:hover {
  transform: scale(0.95);
}
